import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./NavBar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import CustomButton from "../../../InputFields/CustomButton/CustomButton";
import LoginPopup from "./LoginPopup/LoginPopup";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function NavBar() {
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open2 = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <Box className="navbar">
      <LoginPopup
        open={openLoginPopup}
        handleClose={() => setOpenLoginPopup(false)}
      />
      <Box className="top-nav">
        <Grid container className="row" columnSpacing={3} alignItems="center">
          <Grid xs={12}>
            <Box
              display="flex"
              justifyContent="flex-end"
              gap="12px"
              alignItems="center"
            >
              <a
                style={{
                  lineHeight: "28px",
                  fontWeight: 400,
                  color: COLORS.primary,
                  fontSize: "16px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
                href="mailto:mailto:info@walnnut.com"
              >
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="#0C4D85"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "4px" }}
                >
                  <path
                    d="M14.5136 0.504938C14.4497 0.498354 14.3853 0.498354 14.3214 0.504938H1.19644C1.11232 0.506234 1.02875 0.518847 0.947998 0.542438L7.72144 7.28775L14.5136 0.504938Z"
                    fill="#0C4D85"
                  />
                  <path
                    d="M15.2074 1.15649L8.38242 7.95337C8.20677 8.12798 7.96916 8.22599 7.72148 8.22599C7.47381 8.22599 7.2362 8.12798 7.06055 7.95337L0.296484 1.20806C0.27569 1.28448 0.264665 1.36323 0.263672 1.44243V10.8174C0.263672 11.0661 0.362444 11.3045 0.538259 11.4803C0.714075 11.6562 0.952531 11.7549 1.20117 11.7549H14.3262C14.5748 11.7549 14.8133 11.6562 14.9891 11.4803C15.1649 11.3045 15.2637 11.0661 15.2637 10.8174V1.44243C15.2599 1.34477 15.241 1.24829 15.2074 1.15649ZM1.84336 10.8174H1.1918V10.1471L4.59961 6.76743L5.26055 7.42837L1.84336 10.8174ZM14.3168 10.8174H13.6605L10.2434 7.42837L10.9043 6.76743L14.3121 10.1471L14.3168 10.8174Z"
                    fill="#0C4D85"
                  />
                </svg>
                info@walnnut.com
              </a>
              <p
                style={{
                  lineHeight: "28px",
                  fontWeight: 400,
                  color: COLORS.primary,
                  fontSize: "16px",
                }}
              >
                For any queries{" "}
                <a
                  href="tel:+022 6781 7835"
                  style={{
                    lineHeight: "28px",
                    fontWeight: 400,
                    color: COLORS.primary,
                    fontSize: "16px",
                    marginRight: "12px",
                  }}
                  className="blinker_a"
                >
                  <svg
                    width="11"
                    height="14"
                    viewBox="0 0 11 14"
                    fill="#0C4D85"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: "6px",
                      position: "relative",
                      top: "1px",
                    }}
                  >
                    <path
                      d="M4.66611 6.0391L5.74011 5.0551C6.03378 4.78552 6.24018 4.43433 6.33284 4.04661C6.42549 3.65889 6.40017 3.25232 6.26011 2.8791L5.80211 1.6561C5.631 1.19952 5.29225 0.825346 4.85488 0.609819C4.41751 0.394292 3.91442 0.353623 3.44811 0.496097C1.73211 1.0211 0.413111 2.6161 0.819111 4.5101C1.08611 5.7561 1.59711 7.3201 2.56511 8.9841C3.53511 10.6521 4.64311 11.8841 5.59311 12.7501C7.02711 14.0551 9.07711 13.7291 10.3961 12.4991C10.7497 12.1694 10.9642 11.7173 10.996 11.235C11.0278 10.7526 10.8744 10.2763 10.5671 9.9031L9.72711 8.8831C9.47377 8.57465 9.13392 8.34906 8.7513 8.23536C8.36868 8.12166 7.96079 8.12505 7.58011 8.2451L6.19211 8.6821C5.83366 8.31217 5.51954 7.90174 5.25611 7.4591C5.00184 7.01145 4.80368 6.53419 4.66611 6.0381V6.0391Z"
                      fill="#0C4D85"
                    />
                  </svg>
                  022 6781 7835
                </a>{" "}
                (working hours 10:00am - 6:30pm)
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="bottom-nav">
        <Grid container className="row" columnSpacing={3} alignItems="center">
          <Grid xs={3}>
            <img
              src="./images/walnnut-logo.png"
              height="50px"
              alt="Muthoot Insurance"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(COMMON_ROUTES.HOME);
              }}
              style={{ cursor: "pointer" }}
            />
          </Grid>
          <Grid xs={9}>
            <Box>
              <ul className="nav-list">
                <li>
                  <Link
                    onClick={() => {
                      navigate(COMMON_ROUTES.ABOUT_US);
                    }}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Button
                    className="nav-link product-link"
                    id="basic-link"
                    aria-controls={open2 ? "basic-menu2" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleMenuClick}
                    disableRipple
                    sx={{
                      padding: 0,
                      color: COLORS.white,
                      fontWeight: 400,
                      textTransform: "capitalize",
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontFamily: "Roboto",
                      display: "inline-flex",
                      alignItems: "center",
                      "&:hover": {
                        background: "none",
                        color: COLORS.secondary,
                      },
                    }}
                  >
                    Products{" "}
                    <KeyboardArrowDownRoundedIcon sx={{ marginLeft: "4px" }} />
                  </Button>
                  <Menu
                    className="popoverMenu"
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                      },
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-link",
                      sx: {
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontFamily: "Roboto",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                    >
                      Health Insurance
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}
                    >
                      Car Insurance
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}
                    >
                      Two-Wheeler Insurance
                    </MenuItem>
                  </Menu>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(COMMON_ROUTES.CLAIM);
                    }}
                  >
                    Claim
                  </Link>
                </li>

                <li>
                  <CustomButton
                    className="primaryBtn small"
                    text={
                      <>
                        Login{" "}
                        <KeyboardArrowDownIcon
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "4px",
                          }}
                        />
                      </>
                    }
                    fullWidth={false}
                    variant="contained"
                    onClick={() => setOpenLoginPopup(true)}
                  />
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default NavBar;
